import React, { createContext, useState } from "react";

export const HeaderContext = createContext({
  theme: "light",
  pageTheme: "light",
  setTheme: null,
  setPageTheme: null,
  headerVisible: true,
  setHeaderVisible: null,
});

const HeaderProvider = ({ children }) => {
  // theme refers to the header theme set by dynamic actions such as opening the menu or scrolling back
  // up the page, pageTheme refers to the page level theme that sets the initial colour of the header on
  // page load - needed so that when someone scrolls back to the top of the page, we can set the colour
  // back to the correct initial theme colour based on the page setting
  const [theme, setTheme] = useState("light");
  const [pageTheme, setPageTheme] = useState("light");
  const [headerVisible, setHeaderVisible] = useState(true);

  return (
    <HeaderContext.Provider
      value={{
        theme,
        setTheme,
        pageTheme,
        setPageTheme,
        headerVisible,
        setHeaderVisible,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderProvider;
