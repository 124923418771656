import React from "react";
import HeaderProvider from "./src/context/HeaderContext";
import SiteSettingsProvider from "./src/context/SiteSettingsContext";
import "normalize.css";
import "./src/styles/fonts.css";
import "./src/styles/animations.css";

export const wrapRootElement = ({ element }) => {
  return (
    <SiteSettingsProvider>
      <HeaderProvider>{element}</HeaderProvider>
    </SiteSettingsProvider>
  );
};
