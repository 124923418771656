import React, { createContext, useState } from "react";
import { ContentfulSiteSettings } from "../interfaces";

interface SiteSettings {
  settings: ContentfulSiteSettings;
  setSettings: any;
}

export const SiteSettingsContext: React.Context<SiteSettings> = createContext({
  settings: {},
  setSettings: null,
});

const SiteSettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({});

  return (
    <SiteSettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SiteSettingsContext.Provider>
  );
};

export default SiteSettingsProvider;
